"use client"

import * as React from "react";
import { useRouter } from "next/router";
import { getDictionary } from "@/services/i18n.service";
import { DateTimeFormatOptions, GenericObject } from "@/models/UtilsModel";
import { getCookie, setCookie } from "cookies-next";

export const TranslationsContext = React.createContext<GenericObject>({
    dictionary: null,
    setDictionary: () => { },
});

export let updateDictionaryByLocale = (locale?: string) => { };

export let getDictionaryByCurrentLocale = () => { }

export let getLocale = () => { };

export default function TranslationsProvider({ children }: { children: React.ReactNode }) {
    const router = useRouter();
    const { locale } = router;
    const [dictionary, setDictionary] = React.useState<GenericObject | null>(null);

    getDictionaryByCurrentLocale = () => {
        return dictionary;
    };

    const setDictionaryByLocale = (locale: string | undefined) => {
        getDictionary(locale ?? '').then((dictionary) => {
            setDictionary(dictionary);
        });
    }

    updateDictionaryByLocale = (locale?: string) => {
        setDictionaryByLocale(locale ?? getCookie('NEXT_LOCALE') ?? navigator.language);
    }

    getLocale = () => {
        return locale;
    }

    React.useEffect(() => {
        if (!getCookie('NEXT_LOCALE')) {
            setCookie('NEXT_LOCALE', navigator.language);
            router.reload();
        }

        setDictionaryByLocale(locale ?? getCookie('NEXT_LOCALE') ?? navigator.language);

        const options: DateTimeFormatOptions = {
            hourCycle: 'h12',
            hour: 'numeric',
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        };
        console.log('locale time ====> ', new Date().toLocaleDateString(locale));
        console.log('locale time ====> ', new Date().toLocaleDateString(locale).replaceAll('/', '-'));
        console.log('locale time ====> ', new Date().toLocaleDateString(locale, options));

        const number = 3500.57;
        console.log(new Intl.NumberFormat(locale).format(number));

    }, [locale]);

    return (
        <TranslationsContext.Provider value={{ dictionary, setDictionary }}>
            {children}
        </TranslationsContext.Provider>
    );
}
