"use client"

import { GenericObject } from "@/models/UtilsModel";
import * as React from "react";

export const UserContext = React.createContext<GenericObject>({
    user: null,
    setUser: () => { },
});

export default function UserProvider({ children }: { children: React.ReactNode }) {
    const [user, setUser] = React.useState<GenericObject | null>(null);

    return (
        <UserContext.Provider value={{ user, setUser }}>
            {children}
        </UserContext.Provider>
    );
}
