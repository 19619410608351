// axiosInterceptorInstance.tsx

import { openModal } from '@/components/providers/ModalProvider';
import { getDictionaryByCurrentLocale } from '@/components/providers/TranslationsProvider';
import axios from 'axios';
import { getCookie, setCookie } from 'cookies-next';
import { logoutFetch, signOut } from './api/auth.service';

const axiosInterceptorInstance = axios.create({
    baseURL: process.env.NEXT_PUBLIC_API_BASE_URL ?? 'https://randomuser.me/api', // Replace with your API base URL
    /* headers: {
        'Daisy-Session': getCookie("TOKEN"),
    }, */
});

// Request interceptor
axiosInterceptorInstance.interceptors.request.use(
    (config) => {
        // Modify the request config here (add headers, authentication tokens)
        const accessToken = getCookie("TOKEN");

        // If token is present, add it to request's Authorization Header
        if (accessToken) {
            /* if (config.headers) config.headers.token = accessToken; */
            if (config.headers) config.headers['Daisy-Session'] = accessToken;
        }
        return config;
    },
    (error) => {
        // Handle request errors here
        return Promise.reject(error);
    }
);

// Response interceptor
axiosInterceptorInstance.interceptors.response.use(
    (response: any) => {
        // Modify the response data here
        if (response.status == 200) {
            setCookie('TOKEN', response?.data?.session);
        }

        return response;
    },
    async (error) => {
        const originalRequest = error.config;
        /* if (error.response?.status === 401 && !originalRequest._retry && false) {
            originalRequest._retry = true;

            // Try refreshing the token
            const refreshToken = localStorage.getItem('refreshToken');
            try {
                const response = await axios.post('https://api.example.com/refresh', { token: refreshToken });
                const newAccessToken = response.data.accessToken;

                // Store the new token
                localStorage.setItem('accessToken', newAccessToken);

                // Update the original request with the new token
                originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;

                // Retry the original request
                return axiosInterceptorInstance(originalRequest);
            } catch (refreshError) {
                console.log('Token refresh failed', refreshError);
                // Redirect to login if refresh fails
                window.location.href = '/onboarding/login';
            }
        } */

        if (error.response?.status === 401) {
            const dictionary: any = getDictionaryByCurrentLocale();
            openModal(
                dictionary?.onboarding?.dialog?.loginFetchError?.title,
                dictionary?.onboarding?.dialog?.loginFetchError?.message,
                signOut
            );

        }

        return error;
    }
);

export default axiosInterceptorInstance;
