const dictionaries: any = {
    'en': () => import('../dictionaries/en.json').then((module) => module.default),
    'it': () => import('../dictionaries/it.json').then((module) => module.default),
    'it-IT': () => import('../dictionaries/it.json').then((module) => module.default),
    'en-GB': () => import('../dictionaries/en.json').then((module) => module.default),
    'en-US': () => import('../dictionaries/en.json').then((module) => module.default),
}

export const getDictionary = async (locale: string) => {
    if (dictionaries[locale]) {
        return dictionaries[locale]();
    } else {
        return dictionaries['en']();
    }
}
